var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "m-4 flex flex-row-reverse" }, [
      _c("div", { staticClass: "w-1/2" }, [
        _c("img", {
          staticClass: "mr-auto h-20 w-auto mt-4",
          attrs: {
            src: require("@/assets/images/digital-signature.png"),
            alt: "Digitally signed",
          },
        }),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(" MICHAEL L. MCMILLAN, Lt Col, USAF "),
          _c("br"),
          _vm._v(" 340th UFT, Program Manager "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }