var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex justify-center my-4 text-sm" }, [
    _c(
      "div",
      {
        staticClass:
          "p-4 border-2 rounded shadow-xl lex-shrink-0 rint:border-none print:shadow-none",
        staticStyle: { width: "750px" },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }