var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "m-4" }, [
    _c("div", { staticClass: "flex mb-4" }, [
      _c("img", {
        staticClass: "w-auto h-32 mr-auto",
        attrs: {
          src: require("@/assets/images/letter-seal.png"),
          alt: "Letters in Lieu of orders",
        },
      }),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex ml-auto gap-2 print:hidden" },
        [
          _c(
            "button-component",
            { attrs: { "data-cy": "printButton" }, on: { click: _vm.print } },
            [_vm._v("Print")]
          ),
          _c(
            "button-component",
            { attrs: { "data-cy": "backButton" }, on: { click: _vm.back } },
            [_vm._v("Back")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "flex flex-row-reverse" }, [
      _vm._v(_vm._s(_vm.today)),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "text-lg font-bold text-blue-500 uppercase" },
      [
        _c("div", [_vm._v("Department of the air force")]),
        _c("div", [_vm._v("air force reserves command")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }