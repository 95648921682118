
import { Component, Vue } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { formatDate } from '@/services/formatService';
import Routes from '@/router/Routes';

@Component<LetterHeaderComponent>({
  components: {
    ButtonComponent
  }
})
export default class LetterHeaderComponent extends Vue {
  get today(): string {
    const now = new Date();
    return formatDate(now);
  }

  back(): void {
    const fromLogin =
      this.$store.getters['routerModule/fromRoute']?.name === Routes.AUTH_LOGIN;
    if (fromLogin) {
      this.$router.push('/').catch(() => null);
    } else {
      this.$router.back();
    }
  }

  print(): void {
    if (window && window.print) {
      window.print();
    }
  }
}
